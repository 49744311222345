import http from "../http";

export const entList = () => {
  return http({
    method: "get",
    url: `/api/user/entpriseinfo`
  });
};
// 子企业
export const slaveEnterprises = () => {
  return http({
    method: "get",
    url: `/api/user/slaveenterprises`
  });
};
