<template>
  <div class="receipt">
    <div class="panel-title">
      {{ $t("receipt") }}
    </div>
    <div class="search-query-container">
      <div class="search-query">
        <!-- 父子企业 -->
        <el-form v-if="this.userInfo.isMasterEnterprise">
          <el-row :gutter="20">
            <el-col>
              <el-form-item class="app-search-label" :label="$t('paymentDateRange')">
                <search-date class="search-date" :data.sync="searchDate" :format="format" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('enterprise')">
                <el-select v-model="entList" @change="entChange" filterable clearable style="width: 100%;">
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option
                    v-for="item in slaveEnterprisesDatas"
                    :key="item.enterpriseCode"
                    :label="item.enterpriseName"
                    :value="item.enterpriseCode"
                  >
                    <span style="float: left">{{ item.enterpriseName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.enterpriseCode }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item class="app-search-label" :label="$t('accountInfo')">
                <el-select v-model="acctName" style="width: 100%;">
                  <el-option v-bind:label="$t('all')" value=""> </el-option>
                  <el-option
                    v-for="item in accountList"
                    :key="item.acctName"
                    :label="`${item.bankName} ${item.branchName} ${item.acctNo}`"
                    :value="item.acctName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form v-else>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item class="app-search-label" :label="$t('paymentDateRange')">
                <search-date class="search-date" :data.sync="searchDate" :format="format" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item class="app-search-label" :label="$t('accountInfo')">
                <el-select v-model="acctName" style="width: 100%;">
                  <el-option v-bind:label="$t('all')" value=""> </el-option>
                  <el-option
                    v-for="item in accountList"
                    :key="item.acctName"
                    :label="`${item.bankName} ${item.branchName} ${item.acctNo}`"
                    :value="item.acctName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchData">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>
    </div>

    <div class="result-title">{{ $t("tablePaymentCount", { total: total }) }}({{ beginCount }}~{{ endCount }})</div>
    <div class="result-background">
      <div class="result-table">
        <el-table :data="paymentData" @row-click="paymentInfo" style="width: 100%" v-loading="loading">
          <el-table-column prop="transferTime" :label="$t('paymentDateRange')" width="200">
            <template slot-scope="scope">
              {{ dateFormat(scope.row.transferTime, "YYYY/MM/DD") }}
            </template>
          </el-table-column>
          <el-table-column prop="acctNo" :label="$t('accountInfo')" width="170"> </el-table-column>
          <el-table-column prop="dateRange" :label="$t('paymentUsageTime')" min-width="250">
            <template slot-scope="scope">
              <div>
                {{ dateFormat(scope.row.beginTime, "YYYY/MM/DD") + "~" + dateFormat(scope.row.endTime, "YYYY/MM/DD") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" :label="$t('payAmount')" min-width="170">
            <template slot-scope="scope">
              <div>{{ moneyFormat(scope.row.payAmount) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="chargeFee" :label="$t('paymentFee')" width="150">
            <template slot-scope="scope">
              <div>{{ moneyFormat(scope.row.chargeFee) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="refundAmount" :label="$t('paymentRefund')" width="150">
            <template slot-scope="scope">
              <div>{{ moneyFormat(scope.row.refundAmount) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="transferAmount" :label="$t('transferAmount')" min-width="180">
            <template slot-scope="scope">
              <div class="fw600">{{ moneyFormat(scope.row.transferAmount) }}</div>
            </template>
          </el-table-column>
          <el-table-column width="150" align="right" fixed="right" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="paymentInfo(scope.row)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout=" prev, pager, next, sizes, total"
          :total="total"
          :page-size="currentPageSize"
          :page-sizes="[10, 15, 20]"
          :current-page="currentPage"
          @current-change="currentChange"
          @size-change="sizeChange"
          class="common-pagination"
        >
        </el-pagination>
      </div>
    </div>
    <SideLayer :showSideLayer.sync="showDetails" @closeSideLayer="toCloseSideLayer">
      <template>
        <div class="setting-nav-title">{{ $t("paymentDetail") }}</div>
        <div class="explain" v-permission="'Settle.SettleDownload'">
          {{ $t("paymentDetailMore") }}
        </div>
        <el-button
          class="cancel"
          icon="el-icon-download"
          style="margin-top: 16px;"
          :disabled="btnLoading"
          :loading="btnLoading"
          v-permission="'Settle.SettleDownload'"
          v-if="'Settle.SettleDownload'"
          @click="downloadClick"
        >
          {{ $t("paymentDetailMoreDownload") }}
        </el-button>

        <div class="dataTable" style="margin-top:20px">
          <div class="dataItem">
            <td class="name">{{ $t("payDate") }}</td>
            <td class="value">{{ dateFormat(paymentDetails.transferTime, "YYYY/MM/DD") }}</td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("acceptDateRange") }}</td>
            <td class="value">
              {{
                dateFormat(paymentDetails.beginTime, "YYYY/MM/DD") +
                  "~" +
                  dateFormat(paymentDetails.endTime, "YYYY/MM/DD")
              }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("acceptCount") }}</td>
            <td class="value">{{ moneyFormat(paymentDetails.payAmount) }}</td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("refundCount") }}</td>
            <td class="value">{{ moneyFormat(paymentDetails.refundAmount) }}</td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("settlementFee") }}</td>
            <td class="value">{{ moneyFormat(paymentDetails.chargeFee) }}</td>
          </div>
          <div class="dataItem" v-if="paymentDetails.newSettle === true">
            <td class="name">{{ $t("transferFee2") }}</td>
            <td class="value">
              {{ moneyFormat(paymentDetails.transferFeeExcludingTax) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("chargeFee") }}</td>
            <td class="value">
              {{ moneyFormat(paymentDetails.chargeFeeTax) }} <br />
              <span class="tips" v-if="$settings.showFreeTaxText === true">{{ $t("freeTax") }}</span>
            </td>
          </div>
          <div class="dataItem" v-if="paymentDetails.newSettle !== true">
            <td class="name">{{ $t("transferFee") }}</td>
            <td class="value">
              {{ moneyFormat(paymentDetails.transferFee) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("transferCount") }}</td>
            <td class="value fw600">{{ moneyFormat(paymentDetails.transferAmount) }}</td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("accountInfo") }}</td>
            <td class="value">
              {{ paymentDetails.bankInfo }}
            </td>
          </div>
        </div>
      </template>
    </SideLayer>
    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { slaveEnterprises } from "api/enterprise";
import { getSessionStorage } from "@/utils/storage";
import { accountList, enterprisesBankAccountList } from "api/account";
import { paymentList, downloadSettleFile, downloadDepositFile } from "api/payment";
import { executeDownload } from "utils/download";
import { moneyFormat } from "utils/index";
import pagination from "@/mixins/pagination";
import { dateFormat } from "utils/date";
export default {
  mixins: [pagination],
  data() {
    return {
      entList: "",
      slaveEnterprisesDatas: [],
      userInfo: {},
      format: "yyyy/MM/dd",
      searchDate: [],
      acctName: "",
      accountList: [],
      paymentData: [],
      paymentDetails: {},
      showDetails: false,
      loading: false,
      btnLoading: false,
      beginCount: 0,
      endCount: 0,
      downloadDialog: false
    };
  },
  mounted() {
    this.searchDate = [
      this.moment()
        .startOf("month")
        .format("yyyy/MM/DD"),
      this.moment()
        .endOf("day")
        .format("YYYY/MM/DD")
    ];
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    if (this.userInfo.isMasterEnterprise) {
      this.getSlaveEnterprises();
      this.getEnterprisesBankAccountList();
    } else {
      this.getAccountList();
    }
  },
  methods: {
    moneyFormat,
    dateFormat,
    downloadClick() {
      //SMCC及AEON只能下载压缩包
      if (this.$settings.oem && (this.$settings.oem === "smcc" || this.$settings.oem === "aeon")) {
        this.downloadData(2);
      } else {
        this.downloadDialog = true;
      }
    },
    // downloadData(fileType) {
    //   let that = this;
    //   that.downloadDialog = false;
    //   that.btnLoading = true;

    //   if (that.paymentDetails.remittanceId > 0) {
    //     let remittanceType = that.paymentDetails.transferAmount >= 0 ? 0 : 1;
    //     downloadDepositFile({
    //       remittanceId: that.paymentDetails.remittanceId,
    //       remittanceType,
    //       fileFormat: fileType
    //     }).then(response => {
    //       if (response.data) {
    //         executeDownload(response);
    //         this.$message.success(this.$t("success"));
    //       }
    //       this.btnLoading = false;
    //     });
    //   } else {
    //     downloadSettleFile({
    //       transferDate: that.paymentDetails.transferTime,
    //       acctName: that.paymentDetails.acctName,
    //       fileFormat: fileType
    //     }).then(response => {
    //       if (response.data) {
    //         executeDownload(response);
    //         this.$message.success(this.$t("success"));
    //       }
    //       this.btnLoading = false;
    //     });
    //   }
    // },
    downloadData(fileType) {
      let that = this;
      that.downloadDialog = false;
      that.btnLoading = true;
      let remittanceType = that.paymentDetails.transferAmount >= 0 ? 0 : 1;
      downloadSettleFile({
        remittanceId: that.paymentDetails.remittanceId,
        remittanceType,
        transferDate: that.paymentDetails.transferTime,
        acctName: that.paymentDetails.acctName,
        fileFormat: fileType,
        payingBank: that.paymentDetails.payingBank,
        settleType: that.paymentDetails.settleType,
        transferAmount: that.paymentDetails.transferAmount,
        entDisplayName: that.paymentDetails.entDisplayName,
        settleDataSource: that.paymentDetails.settleDataSource
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.$message.success(this.$t("success"));
        }
        this.btnLoading = false;
      });
    },
    resetSearchData() {
      this.acctName = "";
      this.searchDate = [
        this.moment()
          .startOf("month")
          .format("yyyy/MM/DD"),
        this.moment()
          .endOf("month")
          .format("yyyy/MM/DD")
      ];
      if (this.userInfo.isMasterEnterprise) {
        this.entList = "";
        this.getEnterprisesBankAccountList();
      }
    },
    getList(pageIndex = this.currentPage, pageSize = this.currentPageSize) {
      let that = this;
      that.loading = true;
      this.currentPage = pageIndex;
      paymentList({
        beginDate: that.searchDate[0],
        endDate: that.searchDate[1],
        acctName: that.acctName,
        entId: that.entList,
        pageIndex,
        pageSize
      }).then(response => {
        that.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          that.paymentData = response.result;
          that.total = response.totalCount;
          if (response.result.length) {
            that.beginCount = (pageIndex - 1) * pageSize + 1;
            that.endCount = that.beginCount + response.result.length - 1;
          }
        }
      });
    },
    getAccountList() {
      accountList().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          if (response.result.length > 0) {
            this.accountList = response.result;
            this.$forceUpdate();
          }
        }
      });
    },
    // 查询多企业银行账号
    getEnterprisesBankAccountList() {
      enterprisesBankAccountList({ entIdList: this.entList }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.accountList = response.result;
        }
      });
    },
    paymentInfo(val) {
      this.showDetails = true;
      this.paymentDetails = val;
      this.paymentDetails.transferTime = this.paymentDetails.transferTime.split(" ")[0];
    },
    toCloseSideLayer() {
      this.showDetails = false;
    },
    // 获取父子企业数据
    getSlaveEnterprises() {
      slaveEnterprises().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.slaveEnterprisesDatas = response.result;
        } else {
          this.slaveEnterprisesDatas = [];
        }
      });
    },
    // 企业选择事件
    entChange() {
      this.acctName = "";
      this.getEnterprisesBankAccountList();
    }
  }
};
</script>

<style scoped>
.payment-title {
  font-size: 26px;
  font-weight: 600;
}

.search-date {
  width: 100%;
}

.app-search-label {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}

/* .app-search-label >>> .el-form-item__content {
  width: calc(100% - 10px) !important;
} */

.search-select {
  width: 100%;
}

.fw600 {
  font-weight: bold;
}
.name {
  width: 35%;
}
.tips {
  font-size: 14px;
  color: #999;
}
</style>
